
import {defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {
	DocumentField,
	DocumentTable,
	DocumentTableColumn,
	FormTrainingItemField, FormTrainingItemRegionAnchor,
	FormTrainingRegionAnchor
} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import {showDropdownOverlay} from "@/util/StylesHelper";
import FormPositionTrainingTable from "@/apps/squeeze/components/FormPositionTrainingTable.vue";

export default defineComponent({
	name: "FormTrainingRegionAnchor",
	methods: {showDropdownOverlay},
	components: {
		FormPositionTrainingTable,
		InputText,
		Dropdown,
		Button,
	},
	props: {
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		tables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
		itemFieldOfRegionInFormTraining: {
			type: Object as PropType<FormTrainingItemField>,
			default: () => ({}),
		},
		booleanOptions: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Selected Indicator ID */
		const indicatorId = ref<number>(0);

		/** Selected region anchor ID */
		const regionAnchorId = ref<number>(0);

		/** Selected table ID */
		const tableId = ref<number>(0);

		/** Current item field of region in form training */
		const itemField = reactive<FormTrainingItemField>({
			id: undefined,
			columnId: 0,
			valuePattern: '',
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
			accumulateFoundValues: false,
		});

		/** Show Loading on load data */
		const loading = ref(false);

		/** Columns of the Table */
		const columnValues  = ref<DocumentTableColumn[]>([]);

		/** All item fields */
		const allItemFields = ref<FormTrainingItemField[]>([]);

		/** All region anchors */
		const allRegionAnchors = ref<FormTrainingRegionAnchor[]>([]);

		/** All indicators */
		const allIndicators = ref<FormTrainingItemRegionAnchor[]>([]);

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Form Training API endpoint */
		const formTrainingApi = ClientManager.getInstance().squeeze.formTraining;

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/** Set indicator ID and tableID */
		const setIndicatorId = () => {
			if (allIndicators.value && allIndicators.value.length && allIndicators.value[0].id) {
				// set the indicator id
				indicatorId.value = allIndicators.value[0].id;
				// set table id
				tableId.value = allIndicators.value[0].tableId!;
			}
		}

		/** Set region anchor ID */
		const setRegionAnchorId = () => {
			if (allRegionAnchors.value[0] && allRegionAnchors.value[0].id) {
				regionAnchorId.value = allRegionAnchors.value[0].id;
			}
		}

		/** Reset item field */
		const resetItemField = () => {
			Object.assign(itemField, {
				id: undefined,
				columnId: 0,
				valuePattern: '',
				valueRegion: {
					page: 0,
					x0: 0,
					y0: 0,
					x1: 0,
					y1: 0,
				},
				valueIgnoreWhitespace: false,
				accumulateFoundValues: false,
			});

			setIndicatorId();
			setRegionAnchorId();
		}

		/** Get all table column */
		const getAllColumns = () => {
			if (!tableId.value) {
				return;
			}

			loading.value = true;
			documentClassApi.getAllDocumentClassTableColumns(props.documentClassId, tableId.value)
				.then(data => {
					columnValues.value = data;
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all item fields */
		const getItemFields = () => {
			loading.value = true;

			formTrainingApi.getFormItemFields(props.documentClassId, regionAnchorId.value)
				.then(data => {
					allItemFields.value = data;
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 404) {
						allItemFields.value = [];
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Train item field */
		const trainItemField = () => {
			loading.value = true;

			let promise;
			if (itemField.id) {
				promise = formTrainingApi.retrainFormItemField(props.documentClassId, itemField.id, itemField, true);
			} else {
				promise = formTrainingApi.trainFormItemField(props.documentClassId, regionAnchorId.value, itemField, false);
			}

			promise
				.then(() => {
					getItemFields();
					resetItemField();
				}).catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				}).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Change an item field
		 * @param data
		 */
		const changeItemField = (data: any) => {
			Object.assign(itemField, data);

			setRegionAnchorId();
		}

		/**
		 * Deletes an item field
		 * @param data
		 */
		const deleteItemField = (data: any) => {
			loading.value = true;
			formTrainingApi.deleteFormItemField(props.documentClassId, data.id)
				.then(() => {
					resetItemField();
					getItemFields();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all region anchors */
		const getAllRegionAnchors = () => {
			loading.value = true;

			formTrainingApi.getFormItemRegionAnchors(props.documentClassId, String(indicatorId.value))
				.then(data => {
					allRegionAnchors.value = data;

					setRegionAnchorId();
					getItemFields();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 404) {
						allRegionAnchors.value = [];
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all Indicators */
		const getAllIndicators = () => {
			loading.value = true;
			formTrainingApi.getFormTableItemIndicator(props.documentClassId, trainingKey.value!.value!)
				.then(data => {
					allIndicators.value = data;

					setIndicatorId();
					getAllRegionAnchors();
					getAllColumns();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 404) {
						allIndicators.value = [];
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Set marker regions by clicking a row
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: any; index: number}) => {
			const row = event.data;
			emit("onMarkRegion", [row.valueRegion]);
		}

		onMounted(() => {
			Object.assign(itemField, props.itemFieldOfRegionInFormTraining);
			resetItemField();
			getAllIndicators();
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		watch(() => props.itemFieldOfRegionInFormTraining.valuePattern, () => {
			itemField.valuePattern = props.itemFieldOfRegionInFormTraining.valuePattern;
			itemField.valueRegion = props.itemFieldOfRegionInFormTraining.valueRegion;
		})

		return {
			t,
			toast,
			trainingKey,
			indicatorId,
			regionAnchorId,
			tableId,
			itemField,
			loading,
			columnValues,
			allItemFields,
			allRegionAnchors,
			allIndicators,
			onFocusField,
			getItemFields,
			trainItemField,
			changeItemField,
			deleteItemField,
			getAllColumns,
			getAllRegionAnchors,
			onMarkRegion,
		}
	},
});
