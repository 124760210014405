import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e486cdb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-component p-shadow-2",
    value: _ctx.tableData,
    autoLayout: true,
    rowHover: true,
    loading: _ctx.loading,
    onRowClick: _ctx.onMarkRegion,
    scrollable: true,
    scrollHeight: "flex"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound',
				{ entryName: _ctx.tableOrigin === 'indicators' ? _ctx.$t('Squeeze.Training.Indicators')
						: (_ctx.tableOrigin === 'itemFields' ? _ctx.$t('Squeeze.Training.Fields')
							: _ctx.$t('Squeeze.Training.Region') + ' ' + _ctx.$t('Squeeze.Training.Anchor')) })), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        headerClass: "header-center",
        style: {"width":"3rem","text-align":"center"}
      }),
      (_ctx.tableOrigin === 'indicators')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            field: "tableId",
            header: _ctx.$t('Squeeze.Validation.General.Tables') + ' ID',
            style: {"width":"3rem","text-align":"center"}
          }, null, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin !== 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 1,
            field: "description",
            header: _ctx.$t('Squeeze.Training.Description')
          }, null, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin !== 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 2,
            field: "keyWordPattern",
            header: _ctx.$t('Squeeze.Validation.General.Position') + ' ' + _ctx.$t('Squeeze.Training.KeyWord')
          }, null, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin === 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 3,
            field: "valuePattern",
            header: _ctx.$t('Squeeze.Training.KeyWord')
          }, null, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin !== 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 4,
            field: "ignoreWhiteSpaces",
            header: _ctx.$t('Squeeze.Training.IgnoreSpaces')
          }, {
            body: _withCtx((slotProps) => [
              (JSON.stringify(slotProps.data.ignoreWhiteSpaces) === 'true' || JSON.stringify(slotProps.data.ignoreWhiteSpaces) === 'false')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("Squeeze.General.Boolean." + JSON.stringify(slotProps.data.ignoreWhiteSpaces))), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(slotProps.data.ignoreWhiteSpaces), 1))
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin === 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 5,
            field: "valueIgnoreWhitespace",
            header: _ctx.$t('Squeeze.Training.IgnoreSpaces')
          }, {
            body: _withCtx((slotProps) => [
              (JSON.stringify(slotProps.data.valueIgnoreWhitespace) === 'true' || JSON.stringify(slotProps.data.valueIgnoreWhitespace) === 'false')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("Squeeze.General.Boolean." + JSON.stringify(slotProps.data.valueIgnoreWhitespace))), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(slotProps.data.valueIgnoreWhitespace), 1))
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true),
      (_ctx.tableOrigin === 'itemFields')
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 6,
            field: "accumulateFoundValues",
            header: _ctx.$t('Squeeze.Training.MultilineReadout')
          }, {
            body: _withCtx((slotProps) => [
              (JSON.stringify(slotProps.data.accumulateFoundValues) === 'true' || JSON.stringify(slotProps.data.accumulateFoundValues) === 'false')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("Squeeze.General.Boolean." + JSON.stringify(slotProps.data.accumulateFoundValues))), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(slotProps.data.accumulateFoundValues), 1))
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, { style: {"min-width":"8rem","text-align":"right"} }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button p-component p-button-icon-only p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.changeData(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button p-button-rounded p-button-danger p-button-text deleteButtonRow",
            onClick: ($event: any) => (_ctx.deleteData(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "onRowClick"]))
}